<template>
    <div>
        <LoaderComponent v-if="loading"/>

        <div class="d-flex align-items-center mb-1">
            <h6 @click="$router.go(-1)" class="d-flex cursor-pointer mr-1">
                <feather-icon
                    size="20"
                    class="mr-1"
                    icon="ArrowLeftIcon"
                />
            </h6>
            <h5>
                {{ product.item.name }}
            </h5>
        </div>

        <b-row>
            <b-col
                cols="12"
                md="5"
                class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
                <div class="d-flex align-items-center justify-content-center">
                    <b-img
                        v-if="product.item.images.length >0"
                        style="max-height: 200px"
                        :alt="`${product.item.name}-${product.item.id}`"
                        fluid
                        class="product-img"
                        :src="ApiHippoUrl+`/cdn/${product.item.images[0].path}`"
                    />
                    <b-img
                        v-else
                        :alt="`${product.item.name}-${product.item.id}`"
                        fluid
                        style="max-height: 200px"
                        class="card-img-top card-image"
                        :src="require('@/assets/images/no-photo.svg')"
                    />
                </div>
            </b-col>
            <b-col cols="12" md="7">
                <h6 class="item-price my-1">
                    <b>{{ translate.translate('key', 'roznichnayaSena') }}:</b>
                    <span class="text-warning ml-1">{{
                            product.price.common_price.amount | decimal
                        }}</span>
                    {{ product.price.common_price.is_national ? 'сум' : '' }}
                </h6>
                <h6 class="item-price my-1">
                    <b>{{ translate.translate('key', 'optovoyasena') }}:</b>
                    <span v-if="product.price.bulk_price">
                        <span class="text-warning ml-1">{{product.price.bulk_price.amount | decimal }}</span>
                        {{ product.price.bulk_price.currency.is_national ? 'сум' : product.price.bulk_price.currency.name }}
                    </span>
                </h6>

                <b-card-text>
                    <div>
                        <b>{{ translate.translate('key', 'code') }}:</b> {{ product.item.code || 'не укзано' }}
                    </div>
                </b-card-text>

                <b-card-text class="d-flex align-content-center">
                    <div class="font-weight-bold mb-1 mr-1">Количество в упаковке:</div>
                    <div>
                        <div class="font-weight-bold mb-1" v-for="(pack, index) in product.item.package_measurements" :key="index">
                            <span style="margin-right: 5px;">{{ pack.name }}:</span>
                            <span class="text-warning">
                                {{ pack.quantity }} {{ measurementName }}
                            </span>
                        </div>
                    </div>
                </b-card-text>

                <b-card-text>
                    <div>
                        <b>{{ translate.translate('key', 'artikul') }}:</b> {{
                            product.item.sku || 'не укзано'
                        }}
                    </div>
                </b-card-text>

                <b-card-text>
                    <h6>{{ translate.translate('key', 'opisaniya') }}:</h6>
                    <div>{{ product.item.description }}</div>
                </b-card-text>

                <b-card-text class="mt-2">
                    <h6>{{ translate.translate('key', 'xarakteristiki') }}:</h6>
                    <ul class="list-unstyled">
                        <li v-for="atr in product.item.description_attributes" class="dotted-gradient">
                            <div class="d-flex align-items-center mb-1 justify-content-between">
                                        <span>
                                            {{ atr.key }}
                                        </span>
                                <span class="text-info">
                                            <strong>{{ atr.value }}</strong>
                                        </span>
                            </div>
                        </li>
                    </ul>
                </b-card-text>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {mapGetters} from 'vuex'
import translate from '@/translation/translate'
import {ApiHippoUrl} from '@/constants/settings'
import LoaderComponent from "@/components/LoaderComponent";

export default {
    name: 'ProductDetails',
    components: {LoaderComponent},
    directives: {
        Ripple,
    },
    data() {
        return {
            ApiHippoUrl,
            translate,
            loading: false,
            quantity: 1,
            loadingAddCart: false,
        }
    },
    computed: {
        ...mapGetters({
            items: 'getProducts'
        }),
        product() {
            return this.items.find(item => item.item.id === this.$route.params.id)
        },
        measurementName() {
            switch (this.product && this.product.item.measurement) {
                case '1':
                    return "кг"
                case '2':
                    return "метр"
                default:
                    return 'шт'
            }
        }
    },
}
</script>

<style>
.item-description {
    white-space: pre-line;
}

.dotted-gradient {
    background-image: linear-gradient(to right, #b1b1b1 40%, rgba(255, 255, 255, 0) 20%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
}
</style>
